import React, { Component } from 'react';
import './section.scss';
class SectionContainer extends Component {
    showInfo = () => {
        if (!this.props.info) return null
        const { name, title } = this.props.info;
        const bgWhite = this.props.bgColor === 1 ? 'sectionContainer2' : 'sectionContainer';
        const bgDark = this.props.bgColor === 1 ? 'sectionContainerDark2' : 'sectionContainerDark';

        return (
            <React.Fragment>
                <div id={this.props.id}
                className={`${this.props.theme ? bgDark : bgWhite}`} >
                <div className='container'>
                    <h2 className={`${this.props.theme ? 'titleDark' : 'title'}`}>{name}</h2>
                    <h4 className={`${this.props.theme ? 'subTitleDark' : 'subTitle'}`}>{title}</h4>
                    {this.props.children}
                </div>
                </div>
            </React.Fragment>
        )
    }

    render() {
        
        return (
            <>
                {this.showInfo()}
            </>
        );
    }
}

export default SectionContainer;