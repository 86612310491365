import React, { Component } from 'react';
import Logo from '../../assets/img/logoWhite.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, /* faLinkedinIn, */ faYoutube } from '@fortawesome/free-brands-svg-icons';
import './footer.scss';

class Footer extends Component {
    state = {}
    render() {
        return (
            <div className='footer'>
                <div className='container'>
                    <div className='row'>

                        <div className='col-12 col-md-4 col-xl-5'>
                            <img className='logo' src={Logo} alt='Viaguate'></img>
                            <p className='copy'>
                                Copyright &copy; 2020 All Right Reserved by Viaguate S.A
                            </p>
                        </div>

                        <div className='col-12 col-md-5'>
                            <div className='d-flex flex-column'>
                                <p className='titleFooter'>Contact Us</p>
                                <div className='borderBottom'></div>
                            </div>
                            <p className='footerSpace pharagrapFooter'>
                                4 Avenida Norte #19 E 3001-Antigua Guatemala
                            </p>
                            <p className='footerSpace pharagrapFooter'>
                                +(502) 3082 5342
                            </p>
                            <p className='footerSpace pharagrapFooter'>
                                contact@viaguate.com
                            </p>
                        </div>

                        <div className='col-12 col-md-3 col-xl-2'>
                            <div className='d-flex flex-column'>
                                <p className='titleFooter'>Follow Us</p>
                                <div className='borderBottom'></div>
                            </div>

                            <div className='footerSpace'>
                                <a className='d-flex align-items-center' href='https://www.facebook.com/ViaGuateSoft' target='_blank' rel="noopener noreferrer">
                                    <div className='icons'>
                                        <FontAwesomeIcon className='icon' icon={faFacebookF} />
                                    </div>
                                    <span className='pharagrapFooter pl-2'>
                                        Facebook
                                </span>
                                </a>
                            </div>

                            <div className='footerSpace'>
                                <a className='d-flex align-items-center' href='https://www.instagram.com/viaguate/' target='_blank' rel="noopener noreferrer">
                                    <div className='icons'>
                                        <FontAwesomeIcon className='icon' icon={faInstagram} />
                                    </div>
                                    <span className='pharagrapFooter pl-2'>
                                        Instagram
                                </span>
                                </a>
                            </div>
                            
                            <div className='footerSpace'>
                                <a className='d-flex align-items-center' href='https://www.youtube.com/channel/UC8AuCSh1ZW3iEqFIXhx2nvw/' target='_blank' rel="noopener noreferrer">
                                    <div className='icons'>
                                        <FontAwesomeIcon className='icon' icon={faYoutube} />
                                    </div>
                                    <span className='pharagrapFooter pl-2'>
                                        Youtube
                                </span>
                                </a>
                            </div>
                            {/* <div className='footerSpace'>
                                <a className='d-flex align-items-center' href='https://www.linkedin.com/company/via-guate/' target='_blank' rel="noopener noreferrer">
                                    <div className='icons'>
                                        <FontAwesomeIcon className='icon' icon={faLinkedinIn} />
                                    </div>
                                    <span className='pharagrapFooter pl-2'>
                                        Linkedin
                                </span>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;