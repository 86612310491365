import React, { Component } from 'react';
import './about.scss';
//import { HOST_CDN } from '../../config/host_cdn';
import Footer from '../../components/footer/Footer';
import SectionContainer from '../../components/section/Section';
import { CircleCard } from '../../components/Cards/Cards';
import PacmanLoader from "react-spinners/PacmanLoader";
import { proxy } from '../../config/host_proxy';

class About extends Component {
    state = {
        abouts: [],
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.getAbout();
    }

    getAbout = () => {
        fetch(`${proxy}/api/about-uses`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        abouts: result,
                        loading: false
                    });
                },
            )
    }

    render() {
        const { abouts } = this.state;
        const founder = abouts.filter(about => about.puesto === 'Fundador');
        const teamIt  = abouts.filter(about => about.puesto !== 'Fundador');
        return (
            <div className='topHeader'>
                <div className='coverAbout'
                    style={{ backgroundImage: 'url(https://s3.us-south.cloud-object-storage.appdomain.cloud/bucket-viaguate/ourteam_b38808bed6)'/* `url(${HOST_CDN}/ourteam_b7e2a70343)` */ }}>
                    <div className='overlay'>
                        <div className='contentCenter'>
                            <div>
                                <h1 className='titleCover'>Nuestra pasión motiva nuestro trabajo</h1>
                                <h1 className='titleCover'>"Mira quienes somos"</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <SectionContainer
                    bgColor={1} 
                    theme={this.props.theme}
                    info={{ name : 'Nuestro Equipo' }}>
                    <h3 className={`${this.props.theme ? 'subTitleDark' : 'subTitle'} mt-4`}>Fundador</h3>
                    <div className='row d-flex justify-content-center mt-4'>
                        <CircleCard theme={this.props.theme} cardTeam info={founder.length ? founder[0] : []} />
                    </div>
                    <h3 className={`${this.props.theme ? 'subTitleDark' : 'subTitle'} mt-4`}>IT / Desarrollo</h3>
                    <div className='row d-flex justify-content-center mt-4'>
                        {teamIt.map((about, idx) => (
                            <CircleCard theme={this.props.theme} cardTeam key={idx} info={about} />
                        ))}
                        <div className='col-12 d-flex justify-content-center'>
                            <div className="sweet-loading">
                                <PacmanLoader
                                    size={25}
                                    color={"#36D7B7"}
                                    loading={this.state.loading}
                                />
                            </div>
                        </div>
                    </div>
                </SectionContainer>
                <Footer />
            </div>
        );
    }
}

export default About;