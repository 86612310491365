import React, { Component } from 'react';
import './coverImg.scss';
import { HOST_CDN } from '../../config/host_cdn'
import ResponsiveImg from '../../assets/img/a.png';
// import DevImg from '../../assets/img/2.jpg';
// import MobileImg from '../../assets/img/1.jpg';
// import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
class CoverImg extends Component {
    state = {}
    render() {
        const { name, description, image, imageSvg } = this.props.info;
        const type = this.props.count % 2 === 0 ? 'par' : 'inpar'
        return (
            <>
                {type === 'inpar' ?
                    <Fade left>
                        <div className='row d-none d-md-flex align-items-center contentCoverImg'>
                            <Cover img={{image, imageSvg}} />
                            <Text title={name} text={description}/>
                        </div>
                        <div className='row d-flex align-items-center contentCoverImg d-md-none'>
                            <Text title={name} text={description}/>
                            <Cover img={{image, imageSvg}} />
                        </div>
                    </Fade> :
                    <Fade right>
                        <div className='row d-flex align-items-center contentCoverImg'>
                            <Text title={name} text={description} position='left' />
                            <Cover img={{image, imageSvg}} />
                        </div>
                    </Fade>
                }
            </>
        );
    }
}

export default CoverImg;

class Cover extends Component {
    state = {}
    render() {
        const { image, imageSvg } = this.props.img;
        return (
            <div className='col-12 col-md-6 '>
                {imageSvg ?
                    <img src={imageSvg} alt='coverImg' />
                    :
                    <div className='coverImg' style={{ backgroundImage: `url(${image ? HOST_CDN ? `${HOST_CDN}/${image.hash}` : image.url : ResponsiveImg})` }}>
                    </div>
                }
            </div>
        );
    }
}

class Text extends Component {
    state = {}
    render() {
        return (
            <div className='col-12 col-md-6 '>
                <div className={`content ${this.props.position}`}>
                    <h1 className='title'>{this.props.title}</h1>
                    <p className='text'>{this.props.text}</p>
                </div>
            </div>
        );
    }
}