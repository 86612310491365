import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Main from './page/main/Main';
import About from './page/about/About';
import Header from './components/header/Header';

class App extends Component {
  state = { 
    mode: false
   }

   componentDidMount(){
     const modeLS = localStorage.getItem('theme');
     if(modeLS){
       this.setState({ mode: JSON.parse(modeLS) })
     }
   }

   componentDidUpdate(){
     localStorage.setItem('theme', JSON.stringify(this.state.mode))
    }

   changeMode = () =>{
     this.setState({ mode: !this.state.mode }) 
    }

  render() { 
    return ( 
      <Router>
        <Header theme={this.state.mode} changeMode={this.changeMode}/>
      <Switch>
        <Route exact path='/' component={() => <Main theme={this.state.mode} />}/>
        <Route exact path='/about' component={() => <About theme={this.state.mode} />}/>
      </Switch>
    </Router>
     );
  }
}

export default App;
