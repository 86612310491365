import React, { Component } from 'react';
import './header.scss';
import { FaBars, FaChevronRight, FaMoon } from 'react-icons/fa';
import { HashLink as Link } from 'react-router-hash-link';
import { Menu, Sidebar, Popup } from 'semantic-ui-react';
import Logo from '../../assets/img/logoWhite.png';

const style = {
    borderRadius: 2,
    opacity: 0.9,
}

class Header extends Component {
    state = {
        visible: false
    };

    setVisible = () => {
        this.setState({
            visible: !this.state.visible
        })
    }

    changeTheme = () => {
        this.props.changeMode()
    }

    render() {
        return (
            <div className='contentHeader'>
                <div className='container'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='contentLogo'>
                            <Link smooth to='/'>
                                <img className='logoHeader' src={Logo} alt="Viaguate"></img>
                            </Link>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className={`${this.props.theme ? 'contentMoonDark' : 'contentMoon'}`} onClick={this.changeTheme}>
                                <Popup
                                    trigger={<FaMoon className='iconMoon' />}
                                    content={this.props.theme ? 'Modo Claro' : 'Modo Oscuro'}
                                    style={style}
                                    inverted={!this.props.theme}
                                />
                            </div>
                            <div className='d-md-none' onClick={this.setVisible}>
                                {this.state.visible ? <FaChevronRight className='iconMenu' /> :
                                    <FaBars className='iconMenu' />}
                            </div>
                            <MenuLarge />
                        </div>

                    </div>
                </div>
                <Sidebar
                    as={Menu}
                    className='sliderBar'
                    direction='right'
                    animation='overlay'
                    icon='labeled'
                    inverted
                    onHide={() => this.setState({ visible: false })}
                    vertical
                    visible={this.state.visible}
                    width='thin'
                >

                    <Menu.Item>
                        <Link smooth to='/#services' onClick={this.setVisible}>
                            Servicios
                            </Link>
                    </Menu.Item>

                    <Link className='' smooth to='/#products' onClick={this.setVisible}>
                        <Menu.Item>
                            Productos
                            </Menu.Item>
                    </Link>
                    <Link smooth to='/#projects' onClick={this.setVisible}>
                        <Menu.Item>
                        Proyectos
                        </Menu.Item>
                    </Link>
                    {/* <Link smooth to='/about' onClick={this.setVisible}>
                        <Menu.Item>
                            <p className='colorSecondary'>Nosotros</p>
                        </Menu.Item>
                    </Link> */}
                </Sidebar>
            </div>
        );
    }
}

export default Header;


class MenuLarge extends Component {
    state = {}
    render() {
        return (
            <div className='d-none d-md-flex justify-content-end menuLarge'>
                <Link smooth to='/#services'>
                    <p>Servicios</p>
                </Link>
                <Link smooth to='/#products'>
                    <p>Productos</p>
                </Link>
                <Link smooth to='/#projects'>
                    <p>Proyectos</p>
                </Link>
                {/* <Link smooth to='/about'>
                    <p className='colorSecondary'>Nosotros</p>
                </Link> */}
            </div>
        );
    }
}