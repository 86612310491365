import React, { Component } from 'react';
import { HOST_CDN } from '../../config/host_cdn';
import CoverPage from '../../components/coverPage/CoverPage';
import SectionContainer from '../../components/section/Section';
import Footer from '../../components/footer/Footer';
import TechCard, { CardServices, CircleCard } from '../../components/Cards/Cards';
// import FormContact from '../../components/contact/FormContact';
import CoverImg from '../../components/coverImg/CoverImg';
import { proxy } from '../../config/host_proxy';

//img
// import ProductsImg from '../../assets/img/a.jpg';

class Main extends Component {
    state = {
        // products: [],
        // services: [],
        // sections: [],
        // projects: [],
        // technologies: [],
        products: [
            {   enabled: true, _id: "5eb30e07c6a68b0070100709", name: "Diseño de website y tienda online responsive", image: { hash: "Programming-amico_13823d9b87", url: "https://s3.us-south.cloud-object-storage.appdomain.cloud/bucket-viaguate/Programming-amico_13823d9b87" },
                description: "Sitios web corporativos, personales y tiendas en línea que le permiten mejorar o crear su presencia en internet. La creación de nuestros websites es pensada desde el responsive design con el fin de maximizar la conversión de su sitio en contactos y ventas.",
                imageSvg: ''
            },
            {   enabled: true, _id: "5eb30e1dc6a68b007010070a", name: "Desarrollo de apps móviles iOS y Android", image: { hash: "Devices_4a363c2fee", url: "https://s3.us-south.cloud-object-storage.appdomain.cloud/bucket-viaguate/Devices_4a363c2fee"
        },
                description: "Desarrollo y publicación de aplicaciones móviles que llevan la interacción con su negocio a la palma de la mano de sus clientes. Aplicaciones multiplataforma que facilitan el control de sus procesos." 
            },
            {   enabled: true, _id: "5eb30df0c6a68b0070100708", name: "Desarrollo de sistemas personalizados para su empresa", image: { hash: "Mobile_f603b62334", url: "https://s3.us-south.cloud-object-storage.appdomain.cloud/bucket-viaguate/Mobile_f603b62334" },
                description: "Diseñando sistemas para procesos dentro de su negocio. Estos sistemas pueden ser desde un portal web de control hasta una interfaz mediante una aplicación móvil: cada componente está sincronizado y actualizado gracias al poder de la nube." 
            },
        ],
        services: [
            { _id: "5eb30e48c6a68b007010070b", title: "Desarrollo de productos", description: "Cada solución tiene un conjunto configurable de servicios de implementación. Primero, comenzamos estableciendo una base de solución y brindando la funcionalidad esencial para abordar sus requisitos comerciales principales." },
            { _id: "5eb30e5ac6a68b007010070c", title: "Asesoría tecnológica", description: "Traiga a la mesa estrategias de supervivencia de ganar-ganar para garantizar la dominación proactiva." },
            { _id: "5ea8bc547ad70d00406e15cd", title: "Desarrollo de Software", description: "Este modelo permite un desarrollo más rápido de aplicaciones de clase empresarial, mejora la productividad y reduce el tiempo de comercialización." }
        ],
        sections: [
            { _id: "5eb30beb9fa6020040e0db45", segment: "home", name: "Desarrollamos software con tecnología innovadora.", title: "Contactanos para que te ayudemos a implementar tegnología en tu negocio y puedas crecer en tu industria." },
            { _id: "5eb30cb865d448006047a91b", segment: "services", name: "Nuestros Servicios", title: "Ofrecemos variedad de servicios tecnológicos y asesorias informáticas para ayudarte a mejorar tu empresa." },
            { _id: "5eb30da5c6a68b0070100707", segment: "projects", name: "Nuestros Proyectos", title: "Hemos cumplido con los siguientes desarrollos ayudando a mejorar el impacto de las empresas en sus industrias." },
            { _id: "5eb30d60c6a68b0070100706", segment: "technologies", name: "Tecnologias", title: "Tendencias en lenguajes de programacion" },
            { _id: "5eb30d0265d448006047a91c", segment: "products", name: "¿Qué Ofrecemos?", title: "Tendencias tecnologicas con las mejores practicas" },
            { _id: "5eb4613307f393008a8c2397", segment: "contact", name: "Contact Us", title: "Comunicate con nosotros utilizando los siguientes métodos." }
        ],
        projects: [
            {   description: "Travelisimo.com es una agencia de viajes en línea, donde los viajeros pueden encontrar información turística, planificar y reservar sus servicios de viaje.",
                id: "5eb30f11c6a68b007010070e", logo: { hash: "travelisimo-Isotipo-black_cee7543bcb", url: "https://s3.us-south.cloud-object-storage.appdomain.cloud/bucket-viaguate/travelisimo-Isotipo-black_cee7543bcb" },
                name: "Travelisimo", title: "OTA más grande de Guatemala", url: "https://travelisimo.com"
            },
            {   description: "Antigua Rentals es la empresa líder en alquiler de casas vacacionales en Guatemala con más de 15 años de experiencia en el mercado de alquiler.",
                id: "5eb30f11c6a68b007010070f", logo: { hash: "travelisimo-Isotipo-black_cee7543bcb", url: "https://s3.us-south.cloud-object-storage.appdomain.cloud/bucket-travelisimo/Logo_Reload_cb8b8bfc8d" },
                name: "Antigua Rentals", title: "Servicios de alquiler en Guatemala", url: "https://www.antigua-rentals.com/"
            },
            {   description: "Costa Rica Hotels & Homes es una plataforma en línea que ofrece servicios de alojamiento y hospitalidad en toda Costa Rica.",
                id: "5eb30f11c6a68b007010070g", logo: { hash: "travelisimo-Isotipo-black_cee7543bcb", url: "https://www.cr-hotels-homes.com/static/media/Logo%20CHH.13cc1339223f748ac95d.png" },
                name: "Costa Rica Hotels & Homes", title: "Servicios de Alojamiento y Hospitalidad", url: "https://www.cr-hotels-homes.com/"
            }
        ],
        technologies: [
            { enabled: true, _id: "5ede8a1a4e525200112852fd", logo: { hash: "html5-logo_5595113650", url: "https://s3.us-south.cloud-object-storage.appdomain.cloud/bucket-viaguate/html5-logo_5595113650" }, name: "Html 5" },
            { enabled: true, _id: "5ede8a6f4e52520011285303", logo: { hash: "nodejs-logo_82732115e7", url: "https://s3.us-south.cloud-object-storage.appdomain.cloud/bucket-viaguate/nodejs-logo_82732115e7" }, name: "Node Js" },
            { enabled: true, _id: "5ede8a464e525200112852ff", logo: { hash: "reactjs-logo_c92e501624", url: "https://s3.us-south.cloud-object-storage.appdomain.cloud/bucket-viaguate/reactjs-logo_c92e501624" }, name: "React Js" },
            { enabled: true, _id: "5ede8a5c4e52520011285301", logo: { hash: "vuejs-logo_4a454ffe31", url: "https://s3.us-south.cloud-object-storage.appdomain.cloud/bucket-viaguate/vuejs-logo_4a454ffe31" }, name: "Vue Js" }
        ],
        infoContact: null
    }

    componentDidMount() {
        // this.getProducts();
        // this.getServices();
        // this.getSections();
        // this.getProjects();
        // this.getTechnologies();
    }

    getProducts = () => {
        fetch(`${proxy}/api/products?enabled=true`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        products: result
                    });
                },
            )
    }

    getServices = () => {
        fetch(`${proxy}/api/services`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        services: result
                    });
                },
            )
    }

    getSections = () => {
        fetch(`${proxy}/api/pages`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        sections: result,
                        infoContact: {
                            name: 'Contáctenos'
                        }
                    });
                },
            )
    }
    
    getProjects = () => {
        fetch(`${proxy}/api/projects`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        projects: result
                    });

                },
            )
    }

    getTechnologies = () => {
        fetch(`${proxy}/api/technologies?enabled=true`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        technologies: result
                    });

                },
            )
    }

    sendForm = (infoContact) => {
        fetch(`${proxy}/api/contact-forms`, {
            method: 'post',
            body: JSON.stringify(infoContact)
        }).then(res => res.json())
    }


    render() {
        return (
            <>
                <CoverPage info={this.state.sections[0]} />
                <SectionContainer
                    id='services' 
                    bgColor={1}
                    theme={this.props.theme}
                    // info={this.state.sections[1]}
                    info={this.state.sections[1]}
                >
                    <div className='row mt-5 d-flex justify-content-center'>
                        {this.state.services.map((service, idx) => (
                            <CardServices theme={this.props.theme} count={idx + 1} key={idx} info={service} />
                        ))}
                    </div>
                </SectionContainer>

                <SectionContainer 
                    id='products'
                    theme={this.props.theme}
                    info={this.state.sections[4]} 
                >
                    {this.state.products.map((product, idx) => (
                        <CoverImg key={idx} count={idx + 1} info={product} />
                    ))}
                </SectionContainer>
                {/* <SectionContainer img={ProductsImg} bgColor={1} title='' subTitle=''>
                    <div className='pt-5 pb-5'></div>
                </SectionContainer> */}
                <SectionContainer
                    bgColor={1}
                    theme={this.props.theme} 
                    info={this.state.sections[3]} 
                >
                    <div className='row mt-5 d-flex justify-content-center'>
                        {this.state.technologies.map((technology, idx) => (
                            technology.enabled ?
                                <TechCard key={idx} 
                                    img={technology.logo ? HOST_CDN ? `${HOST_CDN}/${technology.logo.hash}` : technology.logo.url : null} 
                                    name1={technology.name} 
                                />
                            : null
                        ))}
                    </div>
                </SectionContainer>

                <SectionContainer
                    id='projects' 
                    theme={this.props.theme}
                    info={this.state.sections[2]} 
                >
                    <div className='row mt-5 d-flex justify-content-center'>
                        {this.state.projects.map((project, idx) => {
                            const projects = { ...project, photo: project.logo}
                            return ( <CircleCard theme={this.props.theme} key={idx} info={projects} /> )
                        })}
                    </div>
                </SectionContainer>

                {/* <SectionContainer 
                    id='contact' 
                    bgColor={1} 
                    theme={this.props.theme}
                    info={this.state.infoContact}>
                    <FormContact sendForm={this.sendForm} />
                </SectionContainer> */}
                <Footer />
            </>
        );
    }
}

export default Main;