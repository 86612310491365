import React, { Component } from 'react';
import './coverPage.scss';
//import { HOST_CDN } from '../../config/host_cdn';
import { HashLink as Link } from 'react-router-hash-link';
import Fade from 'react-reveal/Fade';
import { css } from "@emotion/core";
import BounceLoader from "react-spinners/BounceLoader";

const override = css`
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;


class CoverPage extends Component {

    showInfo = () => {
        if (!this.props.info) return (
            <div className='loadingCover'>
                <div className="sweet-loading">
                    <BounceLoader
                    css={override}
                        size={60}
                        color={"#36D7B7"}
                        loading={true}
                    />
                </div>
            </div>

        )
        // const { name, title } = this.props.info
        return (
            <React.Fragment>
                <div className='contentCenter'>
                    <div className='left'>
                        <Fade bottom>
                            {/* <h1 className='titleCover'>{name}</h1> */}
                            <h1 className='titleCover'>Nuestros Servicios</h1>
                            {/* <p className='text'>{title}</p> */}
                            <p className='text'>Ofrecemos variedad de servicios tecnológicos y asesorias informáticas para ayudarte a mejorar tu empresa.</p>
                        </Fade>
                        <Link smooth to='/#contact'>
                            <button className='btn'>Contactar</button>
                        </Link>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    render() {
        //const img = 'https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80'
        const img = 'https://s3.us-south.cloud-object-storage.appdomain.cloud/bucket-viaguate/photo_3738e5de7b';//`${HOST_CDN}/photo-1522071820081-009f0129c71c_538f6c2d3e`;
        return (
            <div className='coverPage'
                style={{
                    backgroundImage: `url(${img})`
                }}
            >
                <div className='overlayCover'>
                    <div className='container'>
                        {this.showInfo()}
                    </div>
                </div>
            </div>
        );
    }
}

export default CoverPage;