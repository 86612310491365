import React, { Component } from 'react';
import './cards.scss';
import { MdCode } from "react-icons/md";
import { HOST_CDN } from '../../config/host_cdn'
import { AiOutlineShopping, AiOutlineUsergroupAdd } from "react-icons/ai";
import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';

import Logo from '../../assets/img/travelisimo.png';

export class CardServices extends Component {
    state = {}

    icon = (num) => {
        if (num === 1) {
            return (
                <MdCode className='icon' />
            )
        }
        if (num === 2) {
            return (
                <AiOutlineShopping className='icon' />
            )
        }
        if (num === 3) {
            return (
                <AiOutlineUsergroupAdd className='icon' />
            )
        }
    }


    render() {
        const { title, description } = this.props.info
        return (
            <div className='col-12 col-md-4 col-xl-3 mb-3 pl-2 pr-2'>
                <Zoom left>
                    <div className={`${this.props.theme ? 'cardServicesDark' : 'cardServices'}`}>
                        {this.icon(this.props.count)}
                        <h3 className='name'>{title}</h3>
                        <p className='text'>{description}</p>
                    </div>
                </Zoom>

            </div>

        );
    }
}


export class CircleCard extends Component {
    render() {
        const cardTeam = this.props.cardTeam;
        const { name, title, url, description, photo } = this.props.info
        const cardDark = this.props.theme ? 'cardTeamDark' : '';
        return (
            <div className={`col-12 mb-3 ${cardTeam ? 'col-md-4 col-lg-3' : 'col-md-4'}`}>
                <Zoom bottom>
                    <div className={`circleCard ${cardTeam ? `cardTeam ${cardDark}` : ''}`}>
                        <div className='cirlce'>
                            <img src={photo ? HOST_CDN ? `${HOST_CDN}/${photo.hash}` : photo.url : Logo} alt='logo' />
                        </div>
                        <div>
                            <h1 className={`${this.props.theme ? 'titleDark' : 'title'} title`}>{name}</h1>
                            <h3 className={`${this.props.theme ? 'subTitleDark' : 'subTitle'} subTitle`}>{cardTeam ? this.props.info.puesto : title}</h3>
                            <div className='line'></div>
                            <p className='text'>{description}</p>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <a href={url} target="_blank" rel='noopener noreferrer'>
                                <button className='btn'>{cardTeam ? 'LinkedIn' : 'Visitar'}</button>
                            </a>
                        </div>
                    </div>
                </Zoom>
            </div>
        )
    }
}

class TechCard extends Component {
    state = {}
    render() {
        return (
            <div className='col-6 col-md-3 mb-5 d-flex justify-content-center'>
                <Flip left>
                    <div className='TechCard'>
                        <div className='contentImg d-flex justify-content-center'>
                            <img src={this.props.img} alt='tech' />
                        </div>
                        <h3 className='name'>{this.props.name1}</h3>
                        <h3 className='name'>{this.props.name2}</h3>
                    </div>
                </Flip>
            </div>

        );
    }
}

export default TechCard;